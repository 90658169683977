import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/sitesweb',
    name: 'Sitesweb',
    component: () => import('../views/Sitesweb.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue')
  },
  {
    path: '/creationcontenu',
    name: 'Creationcontenu',
    component: () => import('../views/Creationcontenu.vue')
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('../views/Portfolio.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/tarifs',
    name: 'Tarifs',
    component: () => import('../views/Tarifs.vue')
  },
  {
    path: '/CGUCGV',
    name: 'CGUCGV',
    component: () => import('../views/CGUCGV.vue')
  },
  {
    path: '/Mentionslegales',
    name: 'Mentionslegales',
    component: () => import('../views/Mentionslegales.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
