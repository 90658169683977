<template>
    <footer class="bottom parallax-demo" title="footer">
                <div class="footer__social">
                    <a href="https://www.instagram.com/lmcreanum" aria-label="Instragram" alt="Instagram"><i class="fab fa-instagram footer__social__icon"></i></a>
                    <a href="https://www.facebook.com/LMCreationsNumeriques" aria-label="Facebook"  alt="Facebook"><i class="fab fa-facebook footer__social__icon"></i></a>
                    <a href="https://www.malt.fr/profile/lauriemontagner" aria-label="Malt"  alt="Malt"><img src="../assets/maltlogomono.webp" class="footer__social__icon" alt="Logo Malt"/></a>
                    <a href="https://comeup.com/profil/lmcreanum" aria-label="Comeup"  alt="Comeup"><img src="../assets/logo-comeup-blanc.webp" class="footer__social__icon" alt="Logo 5euros.com"/></a>
                </div>
          <div class="footer__content">
            <div class="footer__column">
                <ul class="footer__list">
                  <li><p class="footer__link__text">LM Créations Numériques, freelance en développement numérique dans la Lomagne, le Tarn-et-Garonne, Gers, Landes et Grand Sud-Ouest, également disponible pour des missions en télétravail.</p></li>
                  <li><p class="footer__link__text">Contactez-moi pour tous vos projets de réalisation de sites internet, production vidéo et création de contenu pour le web.</p></li>
                </ul>
              <router-link to="Contact"><div class="footer__button">CONTACTEZ-MOI</div></router-link>
            </div>
            <div class="footer__column__logo">
              <img id="logo" class="logo" src="../assets/LMLOGO.svg" alt="Logo de LM Créations Numériques">
            </div> 
            <div class="footer__column__links">
              <ul class="footer__list">
                <li><router-link class="footer__link" to="Mentionslegales">Mentions Légales</router-link></li>
                <li><router-link class="footer__link" to="CGUCGV">CGU et CGV</router-link></li>
                <li><router-link class="footer__link" to="Privacy">Politique de Confidentialité</router-link></li>
                <li><router-link class="footer__link" to="Sitemap">Plan de site</router-link></li>                
                <li><a href="https://lmcreationsnumeriques.fr" class="footer__link">Site réalisé par <img id="logo" class="logofooter" src="../assets/LMLOGO.svg" alt="Logo de LM Créations Numériques"></a></li>
              </ul>
            </div>
        </div> 
    </footer>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Footer',
  props: {
    msg: String
  },
    mounted() {
      $.fn.parallax = function(opt) {
    return this.each(function() {
        var optDefault = {
                speed: 0.3
            },
            self = $(this),
            optDataSpeed = {
                speed: self.data('parallax-speed')
            },
            bpFirst = self.css('background-position').split(' ')[0],
            bpLast = self.css('background-position').split(' ')[1],
            opts = $.extend(optDefault, opt, optDataSpeed);
        function parallax() {
            var st = $(window).scrollTop(),
                wh = $(window).outerHeight(),
                o = self.offset(),
                h = self.outerHeight(),
                bp = bpFirst + ' ' + 'calc(' + bpLast + ' + ' + (o.top-st)*opts.speed + 'px)';
            if ( (st + wh >= o.top) && (st <= o.top + h) ) {
                self.css('background-position', bp);
            }
        }
        $(window).on('scroll', parallax);
    });
    }

    // Demo
    $('.parallax-demo').parallax({
        speed: 0.4
    });
    }
}
</script>

<style lang="scss">

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.bottom {
  width: 100%;
    position:relative;
    background-image: url("../assets/shore.webp");
    background-size: cover;
    background-position: center;
    overflow: hidden;
    z-index: 0;
}

.parallax-demo {
   background-size: cover;
    background-position: 50% 75%;
    background-attachment: fixed;
}

.bottom:before
{
   width: 150%;
    overflow: hidden!important;
    height: 98vh;
    border-bottom-right-radius: 50% 36vh;
    border-bottom-left-radius: 50% 36vh;
    background-color: #FFF;
    display: inline-block;
    vertical-align: middle;
    margin-top: -96vh;
    position: absolute;
    content: '';
    left: -25%;
}

footer {
	display: block;
	overflow: hidden;
    width: 100%;
    position: relative;
    padding-top: 100px;
    font-family: "Open Sans";
    font-size: small;
    color: white;
    z-index: 0;
}

.footer {
    &__list {
    list-style-type: none;
    @media screen and (max-width: 750px){
        padding: 0;
        width: fit-content;
         }
    }
    &__content {
	width: 80%;
	display: flex;
	margin: auto;
	box-sizing: border-box;
    padding: 30px 50px;
    align-items: center;
    @media screen and (max-width: 980px){
            padding: 30px 0;
            }
    @media screen and (max-width: 750px){
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
         }
    }
    &__column {
    display: block;
	width: 34%; 
    text-align: left;
    @media screen and (max-width: 750px){
        width: 90%;
        margin-top: 25px;
    }
        &__logo {
        display: block;
        width: 34%;
        text-align: center;
        @media screen and (max-width: 750px) {
            display: none;
            }
        }
        &__links {
            display: block;
            width: 34%; 
            text-align: left;  
            @media screen and (max-width: 750px){
                width: 90%;
                margin-top: 25px;
                }
            }
    }
    &__link {
    display: block;
	margin-bottom: 15px;
    text-decoration: none;
    color: black;
    flex-direction: row;
    align-items: center;
    transition: all 250ms;
    width: fit-content;
    &::after {
        display:block;
        content: '';
        border-bottom: solid 2px white;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
    }
    &:hover::after {
      transform: scaleX(1);
    }
    &__text {
        margin: 0;
        text-decoration: none;
        color: white;
        margin-bottom: 15px;
        }    
    }
    &__logo {
        width: 100px;
        margin-top: 40px;
    }
    &__icon {
        margin-right: 10px;
    }
    &__button {
        background: linear-gradient(135deg, rgba(137,219,167,1) 0%, rgba(62,120,195,1) 100%);
        text-align: center;
        padding: 15px;
        padding-bottom: 12px;
        border-radius: 50px;
        margin: auto;
        transition: all 500ms;
        color: white;
        font-family: 'YANONE KAFFEESATZ';
        font-size: x-large;
        max-width: 200px;
        position: relative;
        margin-top: 20px;
        border: 0.5px solid rgba(62,120,195,1);
        z-index: 1;
            &:hover {
                &::after{
                    opacity: 1;
                }
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(135deg, rgba(137,219,167,1) -50%, rgba(62,120,195,1) 50%);
                opacity: 0;
                z-index: -1;
                transition: all 500ms;     
                border-radius: 50px;
            }
        &:focus {
            &::after{
                    opacity: 1;
                }
        }
        @media screen and (max-width: 750px){
            width: fit-content;
            box-sizing: border-box;
            margin-bottom: 5px;
            } 
        }
    &__lm {
        width: 20px;
        vertical-align: middle;
        margin-left: 5px;
    }
}

.logofooter {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    filter: invert(87%) sepia(100%) saturate(2%) hue-rotate(96deg) brightness(105%) contrast(101%);
}

.footer__social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    &__icon {
        width: 30px;
        height: 30px;
        font-size: 30px;
        color: white!important;
        margin: 0 20px;
        transition: all 250ms;
        &:hover {
            transform: scale(1.2);
        }
    }
}



.curvebottom {
    transform: rotate(180deg);
    width: 100%;
    position: absolute;
    z-index: 2;
    top: -54px;
    left: 0;
    filter: invert(87%) sepia(100%) saturate(2%) hue-rotate(96deg) brightness(105%) contrast(101%);
}

</style>