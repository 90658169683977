<template>
    <header class="header" id="header">
         <div class="headerblock">
            <router-link alt="Retour accueil" aria-label="Retour accueil"  to="/"><img id="logo" class="logo" src="../assets/LMLOGO.svg" alt="Logo de LM Créations Numériques"></router-link>
            <nav>
                <div class="conteneur-nav">
                    <input class="menu-btn" type="checkbox" id="menu-btn" v-model="checkedMenue"/>
                    <label class="menu-icon" for="menu-btn"><span id="navicon" class="navicon"></span></label>
                    <ul @click="uncheck(checkedMenue)" class="menu" id="menu">
                        <router-link to="/"><li id="home" class="deroulant">Accueil</li></router-link>
                        <li id="services" class="deroulant"><router-link to="Services">Services</router-link>
                            <ul class="sous">
                              <div id="souscolor">
                                <router-link to="Sitesweb"><li class="sousli">Sites web</li></router-link>
                                <router-link to="Videos"><li class="sousli">Vidéos</li></router-link>
                                <router-link to="Creationcontenu"><li class="sousli"> Création de contenu</li></router-link>
                              </div>
                            </ul>
                            </li>
                            <router-link to="Portfolio"><li class="deroulant">Portfolio</li></router-link>
                            <router-link to="Tarifs"><li class="deroulant">Tarifs</li></router-link>
                            <router-link to="Contact"><li class="deroulant" id="contactbutton">Contact</li></router-link>
                    </ul>
                </div>
                </nav>
         </div>
        </header>
</template>

<script>
//import AnchorRouterLink from 'vue-anchor-router-link'

export default {
  name: 'Header',
  props: {
    msg: String
  }/*,
  components: {
    AnchorRouterLink
    },*/,
    data: function () {
     return {
         checkedMenue: false
     }
  },
    mounted() {
           window.onscroll = function() {scrollFunction()};

           const header = document.getElementById("header");
           const menu = document.getElementById("menu");
           const contact = document.getElementById("contactbutton");
           const souscolor = document.getElementById("souscolor");
           const logo = document.getElementById("logo");
           const burger = document.getElementById("navicon");
           const deroulant = document.getElementsByClassName("deroulant");
           const sousli = document.getElementsByClassName("sousli");

          function scrollFunction() {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
              header.classList.add("active");
              menu.classList.add("activemenu");
              contact.classList.add("activecontact");
              souscolor.classList.add("souscolor");
              logo.classList.add("logoactive");
              burger.classList.add("naviconactive");
              for(var i=0; i<deroulant.length; i++) { 
                      deroulant[i].classList.add("deroulantactive");
                    }
              for(var k=0; k<sousli.length; k++) { 
                      sousli[k].classList.add("sousliactive");
                    }
            } else {
              header.classList.remove("active");
              menu.classList.remove("activemenu");
              contact.classList.remove("activecontact");
              souscolor.classList.remove("souscolor");
              logo.classList.remove("logoactive");
              burger.classList.remove("naviconactive");
              for(var j=0; j<deroulant.length; j++) { 
                      deroulant[j].classList.remove("deroulantactive");
                    }
              for(var l=0; l<sousli.length; l++) { 
                      sousli[l].classList.remove("sousliactive");
                    }
            }
          }
    },
    methods: {
    uncheck: function() {
      this.checkedMenue = !this.checkedMenue;
    }
  }
}
</script>

<style lang="scss">


/*********** GLOBAL ************/
.router-link-active {
  z-index: 30;
}

a {
  color: inherit!important;;
}

/******************** ON SCROLL **************/


.logo {
  height: 50px;
  padding: 0;
  margin: 10px 0;
  filter: invert(87%) sepia(100%) saturate(2%) hue-rotate(96deg) brightness(105%) contrast(101%);
  transition: all 0.25s;
  margin-bottom: 5px;
}

.logoactive {
  filter: invert(0%) sepia(3%) saturate(13%) hue-rotate(79deg) brightness(94%) contrast(103%);
}

.active {
  background-color: white!important;
}

.activemenu {
  color: #000!important;
}

/************** MOBILE FIRST ***************/

.header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    text-transform: uppercase;
    scroll-behavior: smooth;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: x-large;
    transition: all ease-out .5s;
    color: #fff;
    z-index: 100!important;
  }

.headerblock {
    width: 90%;
    margin: auto;
}

nav {
  position: fixed;
    right: 0;
    top: 0;
    width: 100%;
}

  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: white;
  }
  
  .deroulant {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
  }
  
  /*.deroulant:hover,
  .header .menu-btn:hover {
    background-color: darken($color: #fff, $amount: 5);
  }*/

  /* menu */ 

  .menu {
    color: #000;
    transition: all ease-out .5s;
  }
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: white;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  .naviconactive, .naviconactive:before, .naviconactive:after {
    background: #333!important;
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 480px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent!important;;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
.conteneur-nav{
    width: 100%;
    text-align: right;
    margin-top: 8px;
}

.sous{
    display: none;
    /*display: contents;
    z-index: 1000;
    padding: 5px;
    box-sizing: border-box;*/
    
}

.sous li{
    flex: 1 1 auto;
    width: fit-content;
    padding: 15px 12px 5px;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all ease-out .25s;
}

#souscolor {
        display: flex;
    flex-flow: column;
    padding: 0 30px;
    align-items: flex-end;
    }

 #souscolor a {
  width: 100%;
}


/********* HEADER DESKTOP ************/

@media screen and (min-width: 980px){
#contactbutton {
  background-color: white;
  color: black;
  border-radius: 50px;
  &::after {
    border-bottom: solid 2px transparent!important;
  }
  &:hover {
    background-color: black;
    color: white;
  }
}

/*********** ON SCROLL */

.activecontact {
  background-color: black!important;
  color: white!important;
  &:hover {
    background-color: lighten(black, 30)!important;
  }
}

.active {
  background-color: white!important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.deroulantactive {
  &::after {
        border-bottom: solid 2px black!important;
  }
}

.sousliactive {
  &::after {
        border-bottom: solid 2px black!important;
  }
}

/************************* */

    .header {
        background-color: transparent;
        box-shadow: none;
      }
    
      .headerblock {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .header ul {
        background-color: rgba(255, 255, 255, 0);;
      }
      
    .header li {
      float: left;
    }

    .menu {
    color: #fff;
    }

    .deroulant {
    padding: 10px 12px 4px;
    margin: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all ease-out .25s;
    &::after {
        display:block;
        content: '';
        border-bottom: solid 2px white;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
    }
    &:hover::after {
      transform: scaleX(1);
    }
  }

    .header .menu {
      clear: none;
      float: right;
      max-height: none;
      margin-right: 20px;
    }
    .header .menu-icon {
      display: none;
    }
      /*.deroulant:hover,
      .header .menu-btn:hover {
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0px 0 9px 1px rgba(0, 0, 0, 0.5);
      }*/
    .conteneur-nav {
      margin-top: 0!important;
      position: static;
    }

    nav > div > ul > li > a{
        border: 0;
    }

    .sous{
        display: none;
        background-color: white;
        position: absolute;
        width: 100%;
        margin-top: 6px;
    }

    #souscolor {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        text-align: left;
        padding: 0 13px 10px;
        border-radius: 10px;
        transition: all ease-out .5s;
        align-items: flex-start;
    }

    .souscolor {      
        background-color: white;
        margin-top: 15px;
    }

    nav > div > ul .deroulant:hover .sous{
        display: flex;
        flex-flow: column wrap;
        width: auto;
        text-align: left;
        margin-left: -36px;
        padding: 0 8px;
        padding-bottom: 5px;
    }
    .sous a{
        border-bottom: none;
    background-color: transparent;
    text-align: left;
    }

    .sous a:hover{
        border-bottom: none;
    }

    .sous li {
      padding: 10px 12px 5px;
    }

    .sousli {
       &::after {
        display:block;
        content: '';
        border-bottom: solid 2px white;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
    }
    &:hover::after {
      transform: scaleX(1);
    }
    }

    #services {
      padding-bottom: 5px;
    }

    #home {
      display: none;
    }
}


</style>