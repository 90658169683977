<template>
<div>
<figure class="intro parallax-demo" title="Image d'intro vague">
</figure>
<img class="logo__head" src="../assets/LMLOGO.webp" alt="Logo de LM Créations Numériques">
<a href="#" class="scroll-down" address="true" aria-label="Descendre au contenu"  alt="Descendre au contenu"><i class="fas fa-anchor"></i></a>
</div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Header',
  props: {
    msg: String
  },
    mounted() {
      /******** Background Parallaxe *****/
      $.fn.parallax = function(opt) {
    return this.each(function() {
        var optDefault = {
                speed: 0.3
            },
            self = $(this),
            optDataSpeed = {
                speed: self.data('parallax-speed')
            },
            bpFirst = self.css('background-position').split(' ')[0],
            bpLast = self.css('background-position').split(' ')[1],
            opts = $.extend(optDefault, opt, optDataSpeed);
        function parallax() {
            var st = $(window).scrollTop(),
                wh = $(window).outerHeight(),
                o = self.offset(),
                h = self.outerHeight(),
                bp = bpFirst + ' ' + 'calc(' + bpLast + ' + ' + (o.top-st)*opts.speed + 'px)';
            if ( (st + wh >= o.top) && (st <= o.top + h) ) {
                self.css('background-position', bp);
            }
        }
        $(window).on('scroll', parallax);
    });
    }
    $('.parallax-demo').parallax({
        speed: 0.4
    });

    /****************** Scroll au clic sur l'ancre */
     $(function() {
    $('.scroll-down').click (function() {
      $('html, body').animate({scrollTop: $('#anchor').offset().top-80 }, 'slow');
      return false;
    });
  });
    }
}
</script>

<style lang="scss">
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.intro {
  width: 100%;
    height: 98vh;
    position:relative;
    background-image: url("../assets/wave.webp");
    background-size: cover;
    background-position: center;
    overflow: hidden;
    margin-bottom: -70vh;
    z-index: -2;
}

.parallax-demo {
   background-size: cover;
    background-position: 50% 50%;
    background-attachment: fixed;
}

.intro img {
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
  mix-blend-mode: multiply;
  object-fit: cover;
  max-height: 98vh;
}

.intro:before
{
   width: 150%;
    overflow: hidden;
    height: 98vh;
    border-top-right-radius: 50% 36vh;
    border-top-left-radius: 50% 36vh;
    background-color: #FFF;
    display: inline-block;
    vertical-align: middle;
    margin-top: 87vh;
    position: absolute;
    content: '';
    left: -25%;
    @media (max-width: 425px) {
		margin-top: 80vh;
	}
}


.fa-anchor {
  background: linear-gradient(308deg, #1c09e0cf 0%, #38e01fc7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scroll-down {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
}

.scroll-down {
  position: absolute;
  bottom: 4vh;
  left: 50%;
  margin-left: -16px;
  display: block;
  font-size: xx-large;
  z-index: 2;
  -webkit-animation: bounce 2s infinite 2s;
  animation: bounce 2s infinite 2s;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  transform: scale(1);
  @media (max-width: 425px) {
		bottom: 3.5vh;
	}
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.logo__head {
  position: absolute;
    right: 0;
    height: 50vh;
    top: 21%;
    opacity: 0.3;
}

</style>